import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledDedicatedServersBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const styledDedicatedServers = css`
  ${styledDedicatedServersBps};
`

export const H4HeaderBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5HeaderBps = css(
  mq({
    width: [328, 422, 504, 504],
    textAlign: ['center'],
    paddingTop: [50, 50, 30, 30],
    paddingBottom: [30]
  })
)

export const PHeaderBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const lineBorderBps = css(
  mq({
    width: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 340],
    marginRight: [0, 89, 200, 340]
  })
)

export const IdProtectBoxBps = css(
  mq({
    width: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    margin: 0 auto;
  }
  p {
    ${PHeaderBps};
  }
  img {
    max-width: 328px;
  }
`

export const lineBorder = css`
  ${lineBorderBps};
  margin: 0 auto;
  height: 1px;
  border-bottom: solid 1px rgba(217, 222, 227, 0.5);
  margin-top: 49.5px;
  margin-bottom: 40.5px;
`

export const IdProtectBox = css`
  ${IdProtectBoxBps};
  margin: 0 auto !important;
`

export const IdProtectList = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const serverList = css`
  h6 {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: 0.25px;
  }
  img {
    width: 180px;
    margin-bottom: 50px;
  }
`

export const managerList = css`
  display: flex;
  align-items: center;
  img {
    margin-right: 16px;
    width: 100px;
  }
  h6 {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: 0.25px;
  }
`
